import { AccordionSection } from '@quintype/em/components/accordion';
import get from 'lodash/get';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateHaveUnsavedChanges, updateStoryPageGeneralSettings } from '../../../../actions/builder-action';
import { IPBState } from '../../../../utils/interfaces';
import { SettingsElements } from '../../../../utils/setting-components';
import styles from './tts-settings.module.css';

export function TextToSpeech(): JSX.Element {
  const {
    enableTTS = false,
    listenButtonTitle = 'Listen to this article',
    playBackSpeedTitle = 'Playback Speed'
  } = useSelector((state: IPBState) => get(state, ['builder', 'config', 'story', 'pageSetting', 'textToSpeech'], {}));
  const dispatch = useDispatch();
  const updateTTSSettings = (key: string, value: string) => {
    const currentConfig = { enableTTS, listenButtonTitle, playBackSpeedTitle };
    const updatedConfig = { ...currentConfig, [key]: value };
    dispatch(updateStoryPageGeneralSettings({ textToSpeech: updatedConfig }));
    dispatch(updateHaveUnsavedChanges(true));
  };

  const ttsConfig = [
    {
      label: 'Text to Speech',
      key: 'enableTTS',
      type: 'toggle',
      defaultValue: enableTTS,
      onChange: updateTTSSettings
    },
    {
      label: 'Listen to this article Button',
      key: 'listenButtonTitle',
      type: 'textField',
      placeHolder: 'Listen to this article',
      defaultValue: listenButtonTitle,
      onChange: updateTTSSettings
    },
    {
      type: 'textField',
      label: 'Playback Speed',
      key: 'playBackSpeedTitle',
      placeHolder: 'Playback Speed',
      defaultValue: playBackSpeedTitle,
      onChange: updateTTSSettings
    }
  ];

  const langDirection = useSelector(state => get(state, ['qt', 'config', 'language', 'direction'], 'ltr'));
  if (langDirection === 'rtl') return <></>;

  return (
    <div className={styles['tts-settings']}>
      <AccordionSection label="Text to speech" isLabelUpperCase index={1}>
        <SettingsElements configOptions={enableTTS ? ttsConfig : [ttsConfig[0]]} />
      </AccordionSection>
    </div>
  );
}
