import { AccordionSection } from '@quintype/em/components/accordion';
import get from 'lodash/get';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateHaveUnsavedChanges, updateStoryPageGeneralSettings } from '../../../actions/builder-action';
import { IPBState } from '../../../utils/interfaces';
import { SettingsElements } from '../../../utils/setting-components';
import { TextToSpeech } from './tts-settings/index';

import shareIcon1 from './svg/share-icon1.svg';
import shareIcon2 from './svg/share-icon2.svg';
import shareIcon3 from './svg/share-icon3.svg';
import shareIcon4 from './svg/share-icon4.svg';

const defaultFixedShareLayout = 'partialShareIcons';
const defaultSocialShareStyle = 'fixed';
const defaultFollowUsLayout = 'top';
const defaultSocialIconsType = 'circular-color-svg';
const defaultSocialShareIconsOrder = ['whatsapp', 'x', 'facebook', 'linkedin', 'reddit', 'email', 'copylink'];
const possibleOptionsForFollowUs = [
  { label: 'Google News', value: 'google-news-url' },
  { label: 'Jio', value: 'jio-url' },
  { label: 'WhatsApp', value: 'whatsapp-url' },
  { label: 'Tiktok', value: 'tiktok-url' },
  { label: 'X', value: 'twitter-url' },
  { label: 'Pinterest', value: 'pinterest-url' },
  { label: 'Instagram', value: 'instagram-url' },
  { label: 'Youtube', value: 'youtube-url' },
  { label: 'Facebook', value: 'facebook-url' },
  { label: 'Telegram', value: 'telegram-url' },
  { label: 'Linkedin', value: 'linkedin-url' },
  { label: 'Reddit', value: 'reddit-url' }
];

const possibleOptionsForShareIcons = [
  { label: 'Whatsapp', value: 'whatsapp' },
  { label: 'X', value: 'x' },
  { label: 'Facebook', value: 'facebook' },
  { label: 'Linkedin', value: 'linkedin' },
  { label: 'Reddit', value: 'reddit' },
  { label: 'Email', value: 'email' },
  { label: 'Copy Link', value: 'copylink' }
];

const possibleOptionsForShareIconsType = [
  { value: 'shareIcon3', svg: shareIcon3 },
  { value: 'shareIcon2', svg: shareIcon2 },
  { value: 'shareIcon1', svg: shareIcon1 },
  { value: 'shareIcon4', svg: shareIcon4 }
];

const defaultShareIconType = 'shareIcon3';
export const StoryPageSettings = () => {
  const dispatch = useDispatch();
  const {
    customShare = '',
    customFollowUs = '',
    socialShareStyle = defaultSocialShareStyle,
    fixedShareLayout = defaultFixedShareLayout,
    socialIconsType = defaultSocialIconsType,
    followUsLayout = defaultFollowUsLayout,
    socialShareIconsOrder = defaultSocialShareIconsOrder,
    followUsOrder = [],
    shareIconType = defaultShareIconType
  } = useSelector((state: IPBState) => get(state, ['builder', 'config', 'story', 'pageSetting'])) || {};

  const updateTemplateSettings = (key: string, value: string) => {
    dispatch(updateStoryPageGeneralSettings({ [key]: value }));
    dispatch(updateHaveUnsavedChanges(true));
  };

  const fixedShareLayoutSettings = [
    {
      label: 'Choose Layout',
      type: 'dropdown',
      key: 'fixedShareLayout',
      onChange: updateTemplateSettings,
      defaultValue: fixedShareLayout,
      options: [
        { label: 'Layout 1', value: 'partialShareIcons' },
        { label: 'Layout 2', value: 'shareButton' }
      ]
    },
    {
      label: 'Social Icon Style',
      type: 'socialShareIcons',
      onChange: updateTemplateSettings,
      key: 'socialIconsType',
      defaultValue: socialIconsType
    }
  ];

  const storyShareStyleSettings = {
    label: 'Social Share Style',
    type: 'radioButton',
    key: 'socialShareStyle',
    defaultValue: socialShareStyle,
    onChange: updateTemplateSettings,
    radioOptions: [
      { name: 'Fixed in story', value: 'fixed' },
      { name: 'Stick to screen', value: 'sticky' }
    ]
  };

  const defaultShareSettings = [
    {
      label: 'Share Label',
      key: 'customShare',
      type: 'textField',
      placeHolder: 'Share',
      defaultValue: customShare,
      onChange: updateTemplateSettings
    },
    {
      label: 'Share Icon',
      key: 'shareIconType',
      type: 'iconSelector',
      defaultValue: shareIconType,
      iconOptions: possibleOptionsForShareIconsType,
      onChange: updateTemplateSettings
    },
    {
      label: 'Separator',
      key: 'separator',
      type: 'separator',
      marginSize: 'space8'
    },
    {
      label: 'Social Share Options',
      key: 'socialShareIconsOrder',
      type: 'iconArrayEditor',
      defaultValue: socialShareIconsOrder,
      onChange: updateTemplateSettings,
      options: possibleOptionsForShareIcons
    },
    {
      label: 'Separator',
      key: 'separator',
      type: 'separator',
      marginSize: 'space16'
    },
    {
      label: 'Follow us',
      key: 'fieldLabel',
      type: 'fieldLabel',
      description: 'The follow us social icons will only be functional if the corresponding link is added in BOLD.'
    },
    {
      label: 'Choose Platforms To Display',
      key: 'followUsOrder',
      type: 'iconArrayEditor',
      defaultValue: followUsOrder,
      onChange: updateTemplateSettings,
      options: possibleOptionsForFollowUs
    },
    {
      label: 'Choose Follow Us display location',
      type: 'radioButton',
      key: 'followUsLayout',
      defaultValue: followUsLayout,
      radioOptions: [
        { name: 'Top of the story', value: 'top' },
        { name: 'End of the story', value: 'end' },
        { name: 'Top & End of the story', value: 'topAndEnd' }
      ],
      onChange: updateTemplateSettings
    },
    {
      label: 'Follow Us Label',
      key: 'customFollowUs',
      placeHolder: 'Follow Us',
      type: 'textField',
      defaultValue: customFollowUs,
      onChange: updateTemplateSettings
    }
  ];

  const storySettingsArray =
    socialShareStyle === 'fixed'
      ? [storyShareStyleSettings, ...fixedShareLayoutSettings, ...defaultShareSettings]
      : [storyShareStyleSettings, ...defaultShareSettings];

  return (
    <>
      <AccordionSection label="Story Share" isLabelUpperCase index={0}>
        <SettingsElements configOptions={storySettingsArray} />
      </AccordionSection>
      <TextToSpeech />
    </>
  );
};

export default StoryPageSettings;
